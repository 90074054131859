<template>
    <div>
        <div class="home-banner-bg">
            <div>
                <BannerTitleItem></BannerTitleItem>
            </div>
            <div class="bottomTab">
            </div>
        </div>
        <div style="display: flex;justify-content: center;">
            <div style="width: 1520px;">
                <div style="text-align: left;margin-top: 20px;font-size: 16px;">
                    <el-breadcrumb separator="/" style="padding-bottom: 20px;">
                        <el-breadcrumb-item :to="{ path: '/enterprise/home' }" style="color: #FFFFFF;">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/smartSite/newsInfoLIst' }">建筑资讯</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <div
                    style="margin-top: 40px;font-size: 20px;font-weight: bold;padding-bottom: 20px;border-bottom: #DCE5F2 solid 1px;margin-bottom: 40px;">
                    建筑资讯</div>

                <div class="scrollViewX"
                    style="display: flex;align-items: center;justify-content: center;margin-bottom: 60px; overflow: hidden;">
                    <div style="margin-right: 20px;" class="reset" :class="typeIndex===index&&'sure'"
                        @click="selectType({item,index})" v-for="(item,index) in typeList" :key="index">
                        {{item.industryInformationClassName}}</div>
                </div>

                <div style="background: #FBFBFB;padding: 20px;">
                    <div style="display: flex;align-items: center;">
                        <div style="margin-right: 10px;width: 80px;text-align: right;">关键字：</div>
                        <el-input style="width: 400px;" placeholder="输入关键字搜索" clearable v-model="keyWord">
                            <template slot="prepend">
                                <el-button slot="append" icon="el-icon-search"></el-button>
                            </template>
                        </el-input>
                        <div class="sercher-reset" @click="resetData">重置</div>
                        <div class="sercher" @click="sercherData">搜索</div>
                    </div>
                    <div style="display: flex;align-items: center;margin-top: 20px;align-items: center;">
                        <div style="margin-right: 10px;width: 80px;text-align: right;">热门标签：</div>

                        <div style="display: flex;align-items: center;justify-content: center;">
                            <div style="margin-right: 20px;" class="" :class="hotIndex===index&&'hotTag'"
                                @click="selectHot({item,index})" v-for="(item,index) in hotList" :key="index">
                                {{item.name}}</div>
                        </div>
                    </div>
                </div>

                <div style="margin-top: 40px;" v-loading="loadingStatus">
                    <div style="margin-bottom: 20px;" v-for="(item,index) in listData" :key="index"
                        @click="goDetail({item})">
                        <div style="display: flex;">
                            <el-image :src="item.informationPictureUrl" style="width: 200px;height: 150px;"></el-image>
                            <div style="background:#FBFBFB ;padding: 20px 20px 20px 40px;flex: 1;text-align: left;">
                                <div style="font-size: 16px;font-weight: bold;">{{item.informationTitle}}</div>
                                <div style="font-size: 14px;color: #888888;margin-top: 20px;" class="line2" v-html="item.informationDetailsText">
                                  </div>
                                <div style="display: flex;align-items: center;margin-top: 30px;"
                                    v-if="item.tagsList.length">
                                    <div style="background: #8094C4;color: #FFFFFF;padding: 6px;margin-right: 16px;"
                                        v-for="(item) in item.tagsList">{{item}}</div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div style="text-align: right; margin-top: 20px;margin-bottom: 20px;">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 50, 100]" :page-size="size"
                            layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                    </div>

                </div>
            </div>
        </div>


    </div>

</template>

<script>
    import { industryInformation, industryInformationCalssList, getCommonTags } from "@/api/bid.js"
    import BannerTitleItem from '../components/banner-title-item/index.vue'
    export default {
        components: {
            BannerTitleItem
        },
        data() {
            return {
                typeList: [],
                typeIndex: 0,
                hotList: [],
                hotIndex: '',
                industryInformationClassId: '-1',
                keyWord: '',
                loadingStatus: false,
                listData: [],
                total: 0,
                currentPage: 1,
                size: 10,
                tags: '',
            };
        },
       

        async created() {
            await this.industryInformationCalssList()
            this.getCommonTags()
            this.getListData()
        },
        mounted() {

        },
        methods: {
            goDetail({ item }) {
                this.$router.push({
                    path: '/enterprise/newsDetail',
                    query: { informationId: item.informationId, fromPage: 'sercher' },
                });
            },
            resetData() {
                this.tags = ''
                this.keyWord = ''
                this.hotIndex = ''
                this.typeIndex = 0
                this.industryInformationClassId = -1
                this.size = 10
                this.listData = []
                this.getListData()
            },
            sercherData() {
                console.log('11111')
                this.size = 10
                this.currentPage = 1
                this.listData = []
                this.getListData()
            },
            selectType({ item, index }) {
                if (this.typeIndex === index) return
                this.typeIndex = index
                this.industryInformationClassId = item.industryInformationClassId
                this.listData = []
                this.getListData()

                console.log('======', item.industryInformationClassId)
            },
            selectHot({ item, index }) {
                this.hotIndex = index
                this.tags = item.name
                this.listData = []
                this.getListData()
            },
            async getCommonTags() {
                try {

                    let { data } = await getCommonTags({ current: 1, size: 50 })
                    this.hotList = data.records
                    // this.typeList = data
                } catch (error) {

                }
            },
            async industryInformationCalssList() {
                try {

                    let { data } = await industryInformationCalssList()

                    this.typeList = data.industryInformationCalssList
                } catch (error) {

                }
            },
            handleSizeChange(val) {

                this.size = val
                this.getListData();
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getListData();
            },
            //开始查询
            async getListData() {
                try {

                    let params = {
                        current: this.currentPage,
                        size: this.size,
                        keyword: this.keyWord,
                        industryInformationClassId: this.industryInformationClassId,
                        tags: this.tags,
                    }
                    this.loadingStatus = true
                    let { data } = await industryInformation(params)

                    data.records.forEach(item => {
                        item.tagsList = []
                        if (item.tags) {
                            let list = item.tags.split(',')
                            item.tagsList = list
                        }
                    });
                    this.loadingStatus = false
                    data.records.forEach(el=>{
                        if(el.informationDetailsText){
                            el.informationDetailsText=this.unescape(el.informationDetailsText)
                        }
                    })
                    this.listData = data.records
                    this.total = data.total
                } catch (error) {
                    this.loadingStatus = false
                    console.log('=======error查询异常', error)

                }
            },
            unescape (html) {
                return html
                    .replace(html ? /&(?!#?\w+;)/g : /&/g, "&amp;")
                    .replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")
                    .replace(/&quot;/g, '"')
                    .replace(/&#39;/g, "'");
            },

        },
    };
</script>

<style scoped>
    .scrollViewX {
        overflow-x: scroll;
        white-space: nowrap;
        margin-top: 20px;
    }

    .scrollViewX::-webkit-scrollbar {
        height: 8px;
    }

    .line2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

    }

    .hotTag {
        background: #EDF4FF;
        padding: 8px;
        font-size: 16px;
        color: #1890FF;
        border-radius: 4px;

    }

    .sercher {
        color: #FFFFFF;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 24px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
    }

    .sercher-reset {
        border: 1px solid #1890FF;
        color: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 10px 24px;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
    }

    .reset {
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #1890FF;
        color: #1890FF;
        padding: 8px 24px;
        font-size: 14px;
        cursor: pointer;
    }

    .sure {
        color: #FFFFFF;
        background: #1890FF;
        border-radius: 2px 2px 2px 2px;
        padding: 8px 24px;
        font-size: 14px;
        cursor: pointer;
    }
</style>